<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-divider></v-divider>

    <!-- Employee reg form -->
    <v-stepper v-model="e1">
      <v-stepper-header :color="$store.state.secondaryColor">
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          :color="$store.state.secondaryColor"
        >
          <span class="font-14">{{ $t("personal information") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="e1 > 2"
          step="2"
          :color="$store.state.secondaryColor"
        >
          <span class="font-14"> {{ $t("physical address") }} </span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :color="$store.state.secondaryColor">
          <span class="font-14">{{ $t("documents") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :color="$store.state.secondaryColor">
          <span class="font-14">{{ $t("job details") }}</span>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form ref="personalInfo">
            <v-row>
              <v-col cols="12" md="6">
                <small class="pa-5"
                  >*{{ $t("indicates required field") }}</small
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="3" class="text-right">
                <v-btn
                  small
                  dark
                  class="mr-4"
                  :color="$store.state.primaryColor"
                  @click="demoPersonalInfo"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">Demo</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-container class="my-1">
              <v-layout row wrap justify-space-between>
                <v-flex xs12 sm12 md3 lg3 class="text-center elevation-1">
                  <v-card class="pa-2" max-width="344"
                    ><v-avatar tile class="profile" size="164">
                      <v-img
                        accept="image/*"
                        :src="imagePreview"
                        alt="a picture of the employee"
                      ></v-img>
                    </v-avatar>
                  </v-card>

                  <input
                    type="file"
                    @change="previewPicture"
                    class="caption mt-2"
                  />
                </v-flex>
                <v-flex xs12 sm12 md9 lg9 class="px-3 caption">
                  <v-layout row wrap class="mt-1">
                    <v-flex xs12 sm4 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("firstname") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.names.first"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("middlename") }} :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.names.middle"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("lastname") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.names.last"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="mt-1">
                    <v-flex xs12 sm4 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("gender") }}* :
                      </span>
                      <v-radio-group
                        v-model="register.gender"
                        :rules="rules.gender"
                        row
                      >
                        <v-radio value="male">
                          <template v-slot:label>
                            <div>M</div>
                          </template>
                        </v-radio>

                        <v-radio value="female">
                          <template v-slot:label>
                            <div>F</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("id-passport") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.employeeIdPassport"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("email") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.email"
                        :rules="rules.email"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap class="mt-1">
                    <v-flex xs12 sm6 md6 lg6 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("telephone") }}* :
                      </span>
                      <vue-tel-input
                        defaultCountry="Ke"
                        mode="International"
                        v-model="register.telephone"
                        :rules="rules.emptyField"
                        style="height: 40px"
                      ></vue-tel-input>
                    </v-flex>
                    <v-flex xs12 sm6 md6 lg6 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("PO BOX") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.pobox"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm12 md4 lg4 class="text-right">
              <v-btn
                dark
                small
                :color="$store.state.primaryColor"
                @click="continueStepper"
                >{{ $t("continue") }}
                <v-icon right dark> mdi-arrow-right-circle </v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="2">
          <v-form ref="address">
            <v-row justify="end">
              <v-col cols="12" md="3" class="text-right">
                <v-btn
                  small
                  dark
                  class="mr-4"
                  :color="$store.state.primaryColor"
                  @click="demoAddress"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">Demo</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-container class="my-1 caption">
              <v-layout row wrap class="mt-1">
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("country") }}* :
                  </span>
                  <v-combobox
                    v-model="register.address.country"
                    :items="countries"
                    @change="selectCountry"
                    :rules="rules.emptyField"
                    outlined
                    dense
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("county") }}*</span
                  >
                  <v-combobox
                    outlined
                    dense
                    v-model="register.address.county"
                    :items="provinces"
                    @change="selectCounty"
                    :rules="rules.emptyField"
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("city-town") }}
                  </span>
                  <v-combobox
                    outlined
                    dense
                    :items="towns"
                    v-model="register.address.city"
                  ></v-combobox>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-1">
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("district-territory") }}
                  </span>
                  <v-combobox
                    outlined
                    dense
                    v-model="register.address.territory"
                    :items="territories"
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("street") }} :
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.address.street"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize">
                    {{ $t("estate") }} :
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.address.estate"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-1">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="font-weight-medium text-capitalize">{{
                    $t("building")
                  }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.address.building"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="font-weight-medium text-capitalize">{{
                    $t("house-suite-number")
                  }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.address.no"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm12 md4 lg4 class="text-right">
              <v-btn
                dark
                small
                class="mr-2"
                :color="$store.state.secondaryColor"
                @click="e1 = 1"
                ><v-icon left dark> mdi-arrow-left-circle </v-icon
                >{{ $t("back") }}</v-btn
              >

              <v-btn
                dark
                small
                :color="$store.state.primaryColor"
                @click="continueStepper"
                >{{ $t("continue")
                }}<v-icon right dark> mdi-arrow-right-circle </v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
      <v-stepper-items>
        <v-stepper-content step="3">
          {{ register.employmentDocuments }}
          <v-form ref="documents">
            <v-container class="my-1 caption">
              <v-layout row wrap class="mt-1">
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("foreigner") }} :
                  </span>
                  <v-switch
                    v-if="register.employmentDocuments.length != 0"
                    :color="this.$store.state.secondaryColor"
                    disabled
                    :label="$t('clear files in order to switch')"
                  ></v-switch>

                  <v-switch
                    v-if="register.employmentDocuments.length == 0"
                    v-model="foreigner"
                  ></v-switch>
                  <v-layout row wrap>
                    <v-flex xs12 sm12 md12 lg12>
                      <v-card
                        class="mt-3"
                        max-width="300"
                        flat
                        tile
                        v-if="foreigner"
                      >
                        <v-list shaped>
                          <v-subheader>{{
                            $t("required documents")
                          }}</v-subheader>

                          <v-list-item-group
                            v-model="selectedForeignerItem"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in ForeignerItems"
                              :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon v-text="item.icon" small></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="text-capitalize body-2"
                                  v-text="item.text"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                      <v-card class="mt-3" max-width="300" flat tile v-else>
                        <v-list shaped>
                          <v-subheader>{{
                            $t("required documents")
                          }}</v-subheader>

                          <v-list-item-group
                            v-model="selectedLocalItem"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in LocalItems"
                              :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon v-text="item.icon" small></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="text-capitalize body-2"
                                  v-text="item.text"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1" v-if="foreigner">
                  <div v-if="selectedForeignerItem == 0">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("working visa") }} :
                    </span>
                    <v-file-input
                      v-model="workingVisa"
                      counter
                      show-size
                      @change="base64WorkingVisa"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedForeignerItem == 1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("cv") }}:
                    </span>
                    <v-file-input
                      counter
                      show-size
                      v-model="cvForeigner"
                      truncate-length="15"
                      @change="base64CV"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedForeignerItem == 2">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("file name") }}:
                    </span>

                    <v-form ref="others">
                      <v-text-field
                        outlined
                        dense
                        v-model="othersFileNameForeigner"
                      ></v-text-field>
                    </v-form>
                    <v-file-input
                      counter
                      v-model="othersForeigner"
                      show-size
                      @change="otherFile"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1 mt-3" v-else>
                  <div v-if="selectedLocalItem == 0">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("national Id") }}:
                    </span>
                    <v-file-input
                      counter
                      v-model="nationalId"
                      show-size
                      truncate-length="15"
                      @change="base64NationalId"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedLocalItem == 1">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("cv") }}:
                    </span>
                    <v-file-input
                      counter
                      v-model="cvLocal"
                      show-size
                      @change="base64CV"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                  <div v-if="selectedLocalItem == 2">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("file name") }}:
                    </span>
                    <v-form ref="others">
                      <v-text-field
                        outlined
                        dense
                        :rules="rules.emptyField"
                        v-model="othersFileNameLocal"
                      ></v-text-field>
                    </v-form>
                    <v-file-input
                      counter
                      v-model="othersLocal"
                      show-size
                      @change="otherFile"
                      truncate-length="15"
                      accept="application/pdf"
                    ></v-file-input>
                  </div>
                </v-flex>
                <!-- display document -->

                <v-flex xs12 sm12 md4 lg4 class="px-1 mt-3 mb-3">
                  <span
                    class="body-2"
                    v-if="register.employmentDocuments.length > 0"
                    >{{ $t("uploaded documents") }}</span
                  >
                  <v-btn
                    x-small
                    color="error darken-2"
                    @click="confirmDelete()"
                    v-if="register.employmentDocuments.length > 0"
                    text
                    style="float: right"
                    >{{ $t("clear documents") }}</v-btn
                  >
                  <div v-if="register.employmentDocuments.length > 0">
                    <v-btn
                      block
                      text
                      class="grey lighten-3 my-1"
                      v-for="documents in register.employmentDocuments"
                      :key="documents.documentType"
                    >
                      <v-layout row wrap>
                        <v-flex xs8 sm8 md8 lg8>
                          {{ documents.documentType }}
                        </v-flex>
                        <v-flex xs2 sm2 md2 lg2>
                          <v-btn
                            x-small
                            right
                            color="info"
                            @click="
                              documentView(
                                documents.documentType,
                                documents.base64
                              )
                            "
                            >{{ $t("view") }}</v-btn
                          >
                        </v-flex>
                        <v-flex xs2 sm2 md2 lg2>
                          <v-btn
                            x-small
                            dark
                            right
                            color="red"
                            @click="
                              {
                                {
                                  confirmDeleteIndividualDocument(
                                    documents.documentType
                                  );
                                }
                              }
                            "
                            >{{ $t("delete") }}</v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-btn>
                  </div>
                  <div v-else>
                    <p class="caption text-center text--disabled">
                      {{ $t("no documents uploaded") }}
                    </p>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm12 md4 lg4 class="text-right">
              <v-btn
                dark
                small
                class="mr-2"
                :color="$store.state.secondaryColor"
                @click="e1 = 2"
                ><v-icon left dark> mdi-arrow-left-circle </v-icon
                >{{ $t("back") }}</v-btn
              >
              <v-btn
                dark
                small
                :color="$store.state.primaryColor"
                @click="continueStepper"
                >{{ $t("continue")
                }}<v-icon right dark> mdi-arrow-right-circle </v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="jobDetails">
            <v-row justify="end">
              <v-col cols="12" md="6" class="text-right">
                <v-btn
                  small
                  dark
                  class="mr-4"
                  :color="$store.state.primaryColor"
                  @click="demoJobDetails"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">Demo</span>
                </v-btn>
                <v-btn
                  small
                  dark
                  class="mr-4"
                  :color="$store.state.secondaryColor"
                  :loading="loading"
                  @click="uploadData"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize">{{
                    $t("register")
                  }}</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-container class="my-1 caption">
              <v-layout row wrap class="mt-1">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("business") }}* :
                  </span>
                  <v-select
                    outlined
                    dense
                    item-text="businessName"
                    item-value="id"
                    :items="businesses"
                    v-model="register.businessId"
                    :rules="rules.emptyField"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="font-weight-medium text-capitalize">
                    {{ $t("employee role") }}* :
                  </span>

                  <v-combobox
                    outlined
                    dense
                    v-model="register.role"
                    :items="roles"
                    :rules="rules.emptyField"
                  ></v-combobox>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-1">
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("employee ID") }} :
                    <span>
                      <v-btn
                        height="5"
                        small
                        text
                        class="blue--text text-lowercase text-decoration-underline"
                        @click="generateEmployeeId"
                      >
                        {{ $t("autogenerate") }}
                      </v-btn>
                    </span></span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="register.employeeId"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize">
                    {{ $t("smployee status") }}* :
                  </span>
                  <v-select
                    outlined
                    dense
                    item-text="branchName"
                    item-value="id"
                    :items="statusList"
                    v-model="register.employeeStatus"
                    :rules="rules.emptyField"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("employee shift")
                    }}<span>
                      <v-btn
                        height="5"
                        small
                        text
                        class="blue--text text-lowercase text-decoration-underline"
                        @click="newShift"
                      >
                        {{ $t("new shift") }}</v-btn
                      >
                    </span></span
                  >
                  <v-autocomplete
                    outlined
                    v-model="register.shiftId"
                    dense
                    :items="shifts"
                    item-text="shiftName"
                    item-value="id"
                    @change="setShiftDetails(register.shiftId)"
                    :rules="rules.emptyField"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row wrap class="mt-1">
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="font-weight-medium text-capitalize">
                    {{ $t("basic pay") }}* :
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.basicPay"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 class="px-1">
                  <span class="font-weight-medium text-capitalize">
                    {{ $t("P.A.Y.E") }}* :
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="register.paye"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm12 md4 lg4 class="text-right">
              <v-btn
                dark
                small
                :color="$store.state.secondaryColor"
                @click="e1 = 3"
                ><v-icon left dark> mdi-arrow-left-circle </v-icon
                >{{ $t("back") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar
      color="success lighten-5 success--text text--darken-3"
      top
      center
      v-model="snackbar"
    >
      {{ $t("employee registered successfully") }}!
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="success darken-3"
          v-bind="attrs"
          @click="snackbar = false"
          :timeout="timeout"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="success lighten-5 success--text text--darken-3"
      top
      center
      v-model="snackDocumentUploaded"
    >
      {{ documentType }} {{ $t("uploaded successfully") }}!
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="success darken-3"
          v-bind="attrs"
          @click="snackDocumentUploaded = false"
          :timeout="timeout"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="error lighten-5 error--text text--darken-3"
      top
      center
      v-model="snackBarMissingDocuments"
    >
      {{ $t("please makes sure all documents are uploaded") }}.
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="error darken-3"
          v-bind="attrs"
          @click="snackBarMissingDocuments = false"
          :timeout="timeout"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="clearDocumentsDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("clear documents") }}
        </v-card-title>
        <v-card-text
          >{{
            $t("are you sure you want to clear the documents list")
          }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="clearDocumentsDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="red darken-1" text @click="clearDocuments()">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteIndividualDocumentsDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("delete document") }}
        </v-card-title>
        <v-card-text
          >{{ $t("are you sure you want to delete") }}
          <span class="font-weight-bold">{{ documentType }}</span>
          {{ $t("from the documents list?") }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteIndividualDocumentsDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteItem()">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="documentsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <EmploymentDocuments
        :documentType="this.documentType"
        :base64="this.base64"
        @documentsDialog="documentsDialog = false"
      ></EmploymentDocuments
    ></v-dialog>
  </v-container>
</template>

<script>
import fb from "firebase/app";
import { format } from "date-fns";

import EmploymentDocuments from "@/components/templates/EmploymentDocuments";
import countryList from "@/data/countries";
import db from "@/plugins/fb";
import imagePreview from "@/assets/blank-profile.svg";

export default {
  components: {
    EmploymentDocuments,
  },
  data: () => ({
    workingVisa: "",
    nationalId: "",
    cvLocal: "",
    cvForeigner: "",
    othersFileNameLocal: "",
    othersLocal: "",
    othersFileNameForeigner: "",
    othersForeigner: "",
    submitLoading: false,
    documentsDialog: false,
    imagePreview,
    base64: "",
    documentType: "",
    clearDocumentsDialog: "",
    deleteIndividualDocumentsDialog: false,
    employmentDocuments: [],
    selectedForeignerItem: 0,
    snackBarMissingDocuments: false,
    snackDocumentUploaded: false,
    selectedLocalItem: 0,
    e1: 1,
    file: "",
    loading: false,
    snackbar: false,
    foreigner: false,
    timeout: 2000,
    provinces: [],
    towns: [],
    territories: [],
    countryList,
    register: {
      avatar: "",
      names: {
        first: "",
        middle: "",
        last: "",
      },
      gender: "",
      email: "",
      telephone: "",
      employeeStatus: "",
      employmentDocuments: [],
      address: {
        country: "",
        county: "",
        city: "",
        territory: "",
        pobox: "",
        street: "",
        estate: "",
        building: "",
        no: "",
      },
      employeeId: "",
      role: "",
      shiftId: "",
      businessId: "",
      dateCreated: format(new Date(), "yyyy-MM-dd"),
    },

    isManager: false,
    isCashier: false,
    isWaiter: false,
    isChef: false,
    shifts: [],
    businesses: [],
    statusList: ["Active", "On leave"],
    roles: ["Cashier", "Shop attendant", "Waiter", "Manager", "Chef", "Other"],
    pdfSrc: "",
  }),

  created() {
    this.getOwnersBusinesses();
    this.getShifts();
    this.generateEmployeeId();
  },

  computed: {
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    imageName() {
      return this.register.businessId + "/" + this.register.employeeId;
    },
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    countries() {
      return this.countryList.map((item) => item.country);
    },
    LocalItems() {
      return [
        {
          text: this.$t("national Id"),
          icon: "mdi-badge-account-horizontal-outline",
        },
        { text: this.$t("cv"), icon: "mdi-book-open-page-variant-outline" },
        { text: this.$t("others"), icon: "mdi-briefcase-plus" },
      ];
    },
    ForeignerItems() {
      return [
        {
          text: this.$t("working visa"),
          icon: "mdi-badge-account-horizontal-outline",
        },
        { text: this.$t("cv"), icon: "mdi-book-open-page-variant-outline" },
        { text: this.$t("others"), icon: "mdi-briefcase-plus" },
      ];
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || "Please enter a valid email",
        ],
        gender: [(v) => (v || "").length > 0 || this.$t("please select one")],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  methods: {
    getShifts() {
      //this.$store.commit("SET_DOCUMENTS", "");
      this.loading = true;
      this.shifts = [];
      if (this.currentBusiness) {
        db.collection("shifts")
          .where("businessId", "==", this.currentBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
        this.loading = false;
      } else {
        db.collection("shifts")
          .where("businessId", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
        this.loading = false;
      }
    },
    continueStepper() {
      if (this.e1 == 1 && this.$refs.personalInfo.validate()) {
        this.e1 = 2;
      } else if (this.e1 == 2 && this.$refs.address.validate()) {
        this.e1 = 3;
      } else if (this.e1 == 3) {
        if (this.register.employmentDocuments.length > 2) {
          this.e1 = 4;
        } else {
          this.snackBarMissingDocuments = true;
        }
      }
    },

    demoPersonalInfo() {
      this.register.names.first = "Maria";
      this.register.names.middle = "Nandi";
      this.register.names.last = "Hausa";
      this.register.email = "maria@email.com";
      this.register.telephone = "+254098765430";
      this.register.address.pobox = "39393-00200, NAIROBI";
      this.register.gender = "female";
      this.register.employeeId = "32301567";
    },

    demoAddress() {
      this.register.address.country = "Kenya";
      this.register.address.county = "Nairobi";
      this.register.address.city = "Nairobi";
      this.register.address.street = "Magadi Road";
      this.register.address.estate = "Rongai";
      this.register.address.building = "Skylar House";
      this.register.address.no = "22";
    },
    demoJobDetails() {
      this.register.role = "Other";
      this.register.businessId = "jhkrcybHkn6udVutdLgu";
      this.register.employeeStatus = this.statusList[0];
    },
    previewPicture(event) {
      this.file = event.target.files[0];
      this.imagePreview = URL.createObjectURL(this.file);
    },
    uploadData() {
      // console.log(JSON.stringify(this.register))
      if (this.$refs.jobDetails.validate()) {
        this.loading = true;

        let storageRef = fb.storage().ref("employees/" + this.imageName);
        let uploadTask = storageRef.put(this.file);

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            error.preventDefault();
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
              this.register.avatar = downloadLink;
              //this.uploadEmploymentDocuments();

              this.submitData();
            });
          }
        );
      }
    },
    uploadEmploymentDocuments() {
      for (var i = 0; i < this.$store.state.documents.length; i++) {
        console.log("file");
        console.log(this.$store.state.documents[i]);
        let storageRef = fb
          .storage()
          .ref(
            "employees/employee-documents/" +
              this.register.employeeId +
              "/" +
              this.$store.state.documents[i].documentType
          );
        let uploadTask = storageRef.put(this.$store.state.documents[i]);

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            error.preventDefault();
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
              var docType = this.$store.state.documents[i].documentType;

              var data = {
                documentType: docType,
                file_link: downloadLink,
              };
              this.register.documentType = data;
            });
          }
        );
      }
    },
    submitData() {
      db.collection("employees")
        .doc(this.register.employeeId)
        .set(this.register)
        .then(() => {
          this.loading = false;
          this.snackbar = true;

          this.registerAsUser();
        });
    },
    base64WorkingVisa(event) {
      if (this.register.employmentDocuments) {
        var accountIndex = this.register.employmentDocuments.findIndex(
          (item) => item.documentType == "workingVisa"
        );
        if (accountIndex == -1) {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments.push({
              documentType: "workingVisa",
              base64: reader.result,
            });
          };

          reader.readAsDataURL(file);
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments[accountIndex] = {
              documentType: "workingVisa",
              base64: reader.result,
            };
          };

          reader.readAsDataURL(file);
        }
      } else {
        let file = event;

        const reader = new FileReader();

        reader.onloadend = () => {
          this.register.employmentDocuments.push({
            documentType: "workingVisa",
            base64: reader.result,
          });
        };

        reader.readAsDataURL(file);
      }
    },
    base64CV(event) {
      if (this.foreigner) {
        if (this.register.employmentDocuments) {
          var accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == "cvForeigner"
          );
          if (accountIndex == -1) {
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments.push({
                documentType: "cvForeigner",
                base64: reader.result,
              });
            };

            reader.readAsDataURL(file);
          } else {
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments[accountIndex] = {
                documentType: "cvForeigner",
                base64: reader.result,
              };
            };

            reader.readAsDataURL(file);
          }
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            //data.base64 = reader.result;
            this.register.employmentDocuments.push({
              documentType: "cvForeigner",
              base64: reader.result,
            });
          };

          reader.readAsDataURL(file);
        }
      } else {
        if (this.register.employmentDocuments) {
          accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == "cvLocal"
          );

          console.log("accoint index local");
          console.log(accountIndex);

          if (accountIndex == -1) {
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments.push({
                documentType: "cvLocal",
                base64: reader.result,
              });
            };

            reader.readAsDataURL(file);
          } else {
            console.log("new");
            let file = event;

            const reader = new FileReader();

            reader.onloadend = () => {
              //data.base64 = reader.result;
              this.register.employmentDocuments[accountIndex] = {
                documentType: "cvLocal",
                base64: reader.result,
              };
            };
            reader.readAsDataURL(file);
          }
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            //data.base64 = reader.result;
            this.register.employmentDocuments.push({
              documentType: "cvLocal",
              base64: reader.result,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    base64NationalId(event) {
      if (this.register.employmentDocuments) {
        var accountIndex = this.register.employmentDocuments.findIndex(
          (item) => item.documentType == "National Id"
        );
        if (accountIndex == -1) {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments.push({
              documentType: "National Id",
              base64: reader.result,
            });
          };

          reader.readAsDataURL(file);
        } else {
          let file = event;

          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments[accountIndex] = {
              documentType: "National Id",
              base64: reader.result,
            };
          };

          reader.readAsDataURL(file);
        }
      } else {
        let file = event;

        const reader = new FileReader();

        reader.onloadend = () => {
          this.register.employmentDocuments.push({
            documentType: "National Id",
            base64: reader.result,
          });
        };

        reader.readAsDataURL(file);
      }
    },

    otherFile(event) {
      if (this.foreigner) {
        if (this.register.employmentDocuments) {
          var accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == this.othersFileNameForeigner
          );

          console.log(accountIndex);

          if (accountIndex == -1) {
            if (this.$refs.others.validate()) {
              let file = event;
              const reader = new FileReader();

              reader.onloadend = () => {
                this.register.employmentDocuments.push({
                  documentType: this.othersFileNameForeigner,
                  base64: reader.result,
                });
              };
              reader.readAsDataURL(file);
            }
          } else {
            if (this.$refs.others.validate()) {
              let file = event;
              const reader = new FileReader();

              reader.onloadend = () => {
                this.register.employmentDocuments[accountIndex] = {
                  documentType: this.othersFileNameForeigner,
                  base64: reader.result,
                };
              };
              reader.readAsDataURL(file);
            }
          }
        } else {
          if (this.$refs.others.validate()) {
            let file = event;
            const reader = new FileReader();

            reader.onloadend = () => {
              this.register.employmentDocuments.push({
                documentType: this.othersFileNameForeigner,
                base64: reader.result,
              });
            };
            reader.readAsDataURL(file);
          }
        }
      } else {
        if (this.register.employmentDocuments) {
          accountIndex = this.register.employmentDocuments.findIndex(
            (item) => item.documentType == this.othersFileNameLocal
          );

          console.log(accountIndex);

          if (accountIndex == -1) {
            if (this.$refs.others.validate()) {
              let file = event;
              const reader = new FileReader();

              reader.onloadend = () => {
                this.register.employmentDocuments.push({
                  documentType: this.othersFileNameLocal,
                  base64: reader.result,
                });
              };
              reader.readAsDataURL(file);
            }
          } else {
            let file = event;
            const reader = new FileReader();

            reader.onloadend = () => {
              this.register.employmentDocuments[accountIndex] = {
                documentType: this.othersFileNameLocal,
                base64: reader.result,
              };
            };
            reader.readAsDataURL(file);
          }
        } else {
          let file = event;
          const reader = new FileReader();

          reader.onloadend = () => {
            this.register.employmentDocuments.push({
              documentType: this.othersFileNameLocal,
              base64: reader.result,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    documentView(documentType, base64) {
      this.documentType = documentType;
      this.base64 = base64;
      this.documentsDialog = true;
    },
    confirmDelete() {
      this.clearDocumentsDialog = true;
    },
    clearDocuments() {
      this.register.employmentDocuments = [];
      this.clearDocumentsDialog = false;
    },
    confirmDeleteIndividualDocument(documentType) {
      this.documentType = documentType;
      this.deleteIndividualDocumentsDialog = true;
    },

    deleteItem() {
      var accountIndex = this.register.employmentDocuments.findIndex(
        (item) => item.documentType == this.documentType
      );

      if (accountIndex != -1) {
        this.deleteIndividualDocumentsDialog = false;
        this.register.employmentDocuments.splice(accountIndex, 1);
      }
    },
    registerAsUser() {
      //set role in users collection.
      this.isManager = this.register.role == "Manager" ? true : false;
      this.isCashier = this.register.role == "Cashier" ? true : false;
      this.isWaiter = this.register.role == "Waiter" ? true : false;
      this.isChef = this.register.role == "Chef" ? true : false;
      db.collection("users")
        .add({
          username: this.register.employeeId,
          password: "upsell2020",
          isEmployee: this.isManager,
          isManager: this.isManager,
          isCashier: this.isCashier,
          isChef: this.isChef,
          isWaiter: this.isWaiter,
          isOwner: false,
          isSuperAdmin: false,
          accessRight: true,
          status: false,
        })
        .then(() => {
          this.createPayrollDetails();
        });
    },
    createPayrollDetails() {
      db.collection("payroll")
        .add({
          employeeId: this.register.employeeId,
          businessId: this.register.businessId,
          basicPay: this.register.basicPay,
          avatar: this.register.avatar,
          paye: this.register.paye,
          names: {
            first: this.register.names.first,
            middle: this.register.names.middle,
            last: this.register.names.last,
          },
          monthlyPay: {
            deductions: [],
            earnings: [],
          },
        })
        .then(() => {
          this.loading = false;
          this.snackbar = true;

          setTimeout(() => this.goBack(), 2000);
        });
    },
    generateEmployeeId() {
      let employeeId = Math.floor(Math.random() * 100000000 + 1);

      this.register.employeeId = employeeId.toString();
    },

    setShiftDetails(id) {
      //set supplier id to product details
      let shift = this.shifts.find((x) => x.id === id);

      this.register.shiftId = shift.id;
    },

    getOwnersBusinesses() {
      let ownersBusinesses = this.$store.getters.getBusinesses;
      if (this.selected) {
        let currentBusiness = ownersBusinesses.find(
          (x) => x.id == this.selected
        );
        this.businesses.push(currentBusiness);
      } else {
        this.businesses = ownersBusinesses;
      }
    },

    selectCountry() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates
    },

    selectCounty() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates

      let i = region.findIndex(
        (x) => x.province == this.register.address.county
      );
      this.towns = region[i].towns;
      this.territories = region[i].territories;
    },

    newShift() {
      this.$router.push({
        name: "CreateShift",
      });
    },
    goBack() {
      this.$router.push({ name: "ViewEmployees" });
    },
  },
};
</script>

<style></style>
